import ShoppingCartContext from "../Context/ShoppingCartContext";
import { useContext } from "react";

import PictureGallery from "../components/PictureGallery"
function StoreItem({id, Name, Picture, Info, Price}) {
    const { increaseCartQuantity, addItemCart, getItemQuantity, decreaseCartQuantity} = useContext(ShoppingCartContext)

    return (
        <>
            <div className="w-full sm:w-[80vw] md:w-[30vw] py-8">
                <PictureGallery Picture={Picture} Name={Name}/>
                         
                <div className="flex flex-col justify-between px-8 py-4 ">
                    <h3 className="md:text-3xl sm:text-3xl text-2xl">{Name}</h3> 
                    <h4 className="md:text-2xl sm:text-2xl text-xl font-bold"> {Price} :-</h4>
                </div>
                <p className="px-8 text-xs">{Info}</p>
                <div className="flex justify-center">
                    {getItemQuantity(id)  > 0? (
                        <div className="mt-6">
                            <div className="flex flex-row items-center">
                                <button onClick={() => increaseCartQuantity(id)} className="w-10 h-10 text-lg hover:bg-[#999999] border-[#463F3A] border-2 rounded-full" >+</button>
                                <div className="p-4 text-lg">
                                    <span className=" mr-2 text-lg font-bold">{getItemQuantity(id)}</span>  
                                    in cart
                                </div>
                                
                                <button onClick={() => decreaseCartQuantity(id)} className="w-10 h-10 text-lg hover:bg-[#999999] border-[#463F3A] border-2 rounded-full">-</button>
                            </div>
                        </div>
                        
                    ) : (
                        <button onClick={() => addItemCart(id)} className="md:text-lg sm:text-lg text-md w-3/4 px-8 p-2 mt-8 rounded-lg hover:bg-[#999999] border-[#463F3A] border-2" >
                            + Lägg Till
                        </button>
                        
                    )

                    }
                </div>  
            </div>
        </>
    );
}

export default StoreItem;