import { FaArrowCircleLeft, FaArrowCircleRight   } from "react-icons/fa";
import { useState } from "react";
function PictureGallery(Picture, Name) {

    let [current, setCurrent]= useState(0);
    let previousSlide = () =>{
        if (current === 0) setCurrent(Picture.Picture.length -1)
        else setCurrent(current - 1)
    }
    let nextSlide = () =>{
        if (current === Picture.Picture.length -1) setCurrent(0)
        else setCurrent(current + 1)
    }
    return (
        <div className="flex flex-row justify-center">
        {Picture.Picture.length > 1 ?(
            <div className="mx-4 flex text-3xl">
                <button onClick={previousSlide}>
                    <FaArrowCircleLeft/>
                </button>
            </div>
        ):null}
            <div className="flex transition ease-out duration-500 justify-center w-[70%]">
                <img src={Picture.Picture[current].imgURL} alt={Name} className="md:h-[200px] sm:h-[300px] h-[150px]"/>
            </div>
        {Picture.Picture.length > 1 ?(
            <div className="flex mx-4 text-3xl">
                <button onClick={nextSlide}>
                <FaArrowCircleRight/>
                </button>               
            </div>
        ):null}
    </div>
    );
}

export default PictureGallery;