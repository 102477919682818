import ShoppingCartContext from "../Context/ShoppingCartContext";
import { useContext } from "react";


import StoreItem from "../components/StoreItem";

function Shop() {
  const { ProductItems } = useContext(ShoppingCartContext);

  // Check if ProductItems is not defined or not an array
  if (!ProductItems || !Array.isArray(ProductItems)) {
    console.error("ProductItems is not defined or not an array");
    return null; // or handle the error appropriately
  }
  
  return (
    <div className="w-full md:w-3/4 mx-auto py-8">
      <h2 className="text-center w-1/2 mx-auto text-4xl p-4 border-b-2 border-[#222020]">Shop</h2>
      
      <div className="flex flex-wrap mx-auto justify-center">
        {ProductItems.map((item) => (
          <div key={item.id} className="">
            <StoreItem {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Shop;