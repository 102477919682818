import { createContext, useEffect, useState, } from "react";

import Products from "../data/data.json"
//Createing context
const ShoppingCartContext = createContext({});


//creating provider component
export function ShoppingCartProvider({ children }) {

  const ProductItems = Products

  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem('cart')) || [])
  const [Price, setPrice] = useState()
  useEffect(() => {

    getCartItems()
    calculateTotalPrice();

  }, [localStorage.getItem('cart')]);

  const calculateTotalPrice = () => {
    let total = 0;
    cartItems.forEach(item => {
        total += ProductItems[item.productID-1].Price * item.quantity;
    });
    setPrice(total);
  };

  const getCartItems = () => {
    const cart = localStorage.getItem('cart');
    setCartItems(cart ? JSON.parse(cart) : [])
  };


    //Adding new item to Cart if not added before 
    const addItemCart = (id) => {
    let found = false;

    

    for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].ProductId === id) {
            cartItems[i].Count += 1;
            found = true;
            break;
        }
    }
    if (!found) {
        const newProduct = { id: cartItems.length + 1, productID: id, quantity: 1 };
        const updatedCart = [...cartItems, newProduct];
        setCartItems(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        // setCartItems(cartItems.concat(newProduct));
    } else {
        setCartItems(cartItems.slice()); // Ensure to trigger a re-render by creating a new reference
    }
    }

    //When called you get quantity of item in Cart by Product ID
    const getItemQuantity = (id) =>{
    return cartItems.find(item => item.productID === id)?.quantity || 0
    }

    const getItemPrice = () =>{
      
      for(let i = 0; cartItems.length > 0; i++){
        if (cartItems[i-1].productID === ProductItems[i-1].id){
          console.log(ProductItems[i-1])
          setPrice(Price+ProductItems[i-1].Price)
        }
      }
      cartItems.find(item => item.productID === ProductItems.id)

      return Price
      }

    //Increase quantity of item in Cart by Product ID
    const increaseCartQuantity = (id) => {
      setCartItems((prevCartItems) => {
        const existingItem = prevCartItems.find((item) => item.productID === id);
    
        if (existingItem === undefined) {
          const updatedCart = [...prevCartItems, { productID: id, quantity: 1 }];
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          return updatedCart;
        } else {
          const updatedCart = prevCartItems.map((item) => {
            if (item.productID === id) {
              return { ...item, quantity: item.quantity + 1 };
            }
            return item;
          });
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          return updatedCart;
        }
      });
    };

    //Decrease quantity of item in Cart by Product ID
    const decreaseCartQuantity = (id) => {
      setCartItems((prevCartItems) => {
        const existingItem = prevCartItems.find((item) => item.productID === id);
    
        if (existingItem && existingItem.quantity === 1) {
          const updatedCart = prevCartItems.filter((item) => item.productID !== id);
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          return updatedCart;
        } else {
          const updatedCart = prevCartItems.map((item) => {
            if (item.productID === id) {
              return { ...item, quantity: item.quantity - 1 };
            }
            return item;
          });
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          return updatedCart;
        }
      });
    };
    

    //Removes item in Cart by Product ID
    const removeFromCart = (id) => {
      setCartItems((prevCartItems) => {
        const updatedCart = prevCartItems.filter((item) => item.productID !== id);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        return updatedCart;
      });
    };
    

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        cartItems,
        addItemCart,
        ProductItems,
        getItemPrice,
        Price
        }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
export default ShoppingCartContext;