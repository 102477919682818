export default function Cookies(){
    return (
        <div className="py-20 w-1/2 mx-auto">
            <h2 className="text-2xl font-bold">Cookies</h2>
            <p className="mt-4">
                Welcome to our website!
                <br /><br />
                We understand that your privacy is important to you. Therefore, we want to be transparent about our use of cookies. Cookies are small text files that are stored on your device when you visit our website. They help us improve your browsing experience by remembering your preferences and settings. We also use cookies to analyze how our website is used and to customize content and advertisements.
                <br /><br />
                We utilize both session cookies and persistent cookies. Session cookies are temporary and are deleted when you close your browser. Persistent cookies remain on your device until they expire or until you delete them. We use persistent cookies to remember your preferences and settings. We do not use any third-party cookies or analytics tools to track your activity on our website.
                <br /><br />
                We believe that your privacy is important, and we want to ensure that your data is secure and protected. We also want to ensure that you have control over your data. You can choose to accept or reject cookies when you visit our website. If you reject cookies, some features on our website may not function as intended. You can also delete cookies from your device at any time.
                <br /><br />
                Cookies are small text files that are stored on your device when you visit our website. They help us improve your browsing experience by remembering your preferences and settings. We also use cookies to analyze how our website is used and to customize content and advertisements.
                <br /><br />
                We are committed to providing you with the best possible experience on our website. If you have any questions or concerns about our use of cookies, please feel free to contact us.
                <br /><br />
            </p>
        </div>
    )
}