import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import CookieConsent from "react-cookie-consent";

import Navbar from "./components/Navbar.js";
import Footer from './components/Footer.js';

import Home from './Pages/Home';
import Shop from './Pages/Shop.js';
import Checkout from './Pages/Checkout.js';
import Contact from './Pages/Contact.js';
import Cookies from './Pages/Cookies.js';

import { ShoppingCartProvider } from './Context/ShoppingCartContext.js';

let Cookie = true
function ChangeCookie(){
  Cookie = !Cookie
}
function App() {
  return (
    <ShoppingCartProvider>
      <Navbar/>
        <div className="h-full w-full bg-[#F4F3EE]" alt="backgound">
            <Routes>
              <Route path="/" exact element={<Home/>} />
              <Route path="/shop" element={<Shop/>}/>
              <Route path="/contact" element={<Contact/>}/>
              <Route path='/checkout' element={<Checkout/>}/>
              <Route path='/cookies' element={<Cookies/>}/>
            </Routes>
        </div>
      <Footer/>
      <CookieConsent debug={Cookie} onAccept={ChangeCookie} style={{ background: "#393636", }} expires={15} acceptOnScroll={ChangeCookie}>This site uses cookies.</CookieConsent>
    </ShoppingCartProvider>
    
  );
}

export default App;
