// Importing necessary modules from React and other packages
import OrderForm from '../components/OrderForm'; // Importing ContactForm component
import Item from '../components/Item'; // Importing Item component
import ShoppingCartContext from "../Context/ShoppingCartContext"; // Importing ShoppingCartContext
import { useContext } from "react"; // Importing useContext hook from React
import { Link } from 'react-router-dom'; // Importing Link component from react-router-dom

import Data from "../data/webbPageText.json"
// Defining the Checkout component
function Checkout() {
  // Destructuring necessary functions and values from the ShoppingCartContext
  const { 
    cartItems, 
    Price
  } = useContext(ShoppingCartContext);

  // JSX for rendering the Checkout component
  return (
    <main className="md:w-[70vw] w-[100vw] mx-auto">
        {/* Checking if there are items in the cart */}
        {cartItems.length > 0 ? (
          <div className='w-full pt-10 bg-white'>
            <div className='mt-10'>
              {/* Rendering each item in the cart */}
              {cartItems.map((item, index) => (
                <Item key={index} {...item}/>
              ))}
            </div>

            {/* Rendering total price and shipping */}
            <div className='md:p-10 pb-20'>
              <div>
                <div className='flex flex-row md:justify-start justify-between bg-gray-300 px-4 pt-4'>
                  <h2 className='md:text-lg text-md font-bold'>
                      TOTALT: 
                  </h2>
                  <h2 className='md:text-lg text-md font-bold md:ml-8 ml-0'>
                    {Price} Kr
                  </h2>
                </div>
                <div className='mb-4 flex flex-row md:justify-start justify-between bg-gray-300 px-4 pb-4'>
                  <h3 className='md:text-lg text-md mr-2'>
                    Frakt: 
                  </h3>
                  <h3 className='md:text-lg text-md ml-12'>
                    0 Kr
                  </h3>
                </div>        
              </div>
              
              {/* Additional information about payment */}
              <div>
                <p className='p-4 font-bold underline decoration-solid'>
                  {Data.Checkout.InfoUnderTotal}
                </p>
              </div>

              {/* Rendering the ContactForm component */}
              <OrderForm/>
            </div>
            
          </div>
            
          ) : (
            // Message displayed when the cart is empty
            <div className='h-[650px] flex justify-center items-center flex-col'>
              <h2 className='text-3xl font-bold text-center'>
                Du har inget i din varukorg 
              </h2>
              {/* Link to shop if the cart is empty */}
              <Link to="/shop" className="hover:bg-[#463F3A] border-[#463F3A] border-2 px-3 py-2 rounded-md text-lg font-medium mt-2">
                <h3>
                  Shop 
                </h3>
              </Link>
            </div>
          )
      }
    </main>
  );
}

export default Checkout; // Exporting the Checkout component
