import { Link } from "react-router-dom";
import Data from "../data/webbPageText.json"


function Home() {
  return (
    <main className="md:w-[70vw] mx-auto py-20 text-center">
        <h2 className="text-5xl">{Data.Home.Rubrik}</h2>
        <p className="text-xl md:w-3/4 w-full mx-auto mt-8 px-10">
          {Data.Home.undertext}
        </p>
        <div className="flex justify-center mt-8">
          <Link to="/shop" className="w-4/12 hover:bg-[#beb2a9] border-[#463F3A] border-2 py-2 rounded-md text-sm font-medium">
            <p className="">
              Shop
            </p>
          </Link>          
        </div>

        <div className="flex flex-row justify-center mt-8 ">
          <img src="/img/dosa.png" alt="dosa" className="md:w-[450px] w-[200px]"/>
          <img src="/img/lås.png" alt="lås" className="md:w-[300px] w-[100px]"/>
        </div>
    </main>
    
  );
}

export default Home;