import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact() {

    // State variables to manage form input values and error messages
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Mail, setMail] = useState('');
    const [Message, setMessage] = useState('');
    const [errore, setError] = useState('');

    // Function to handle form submission
    const sendTest = (event) => {
        event.preventDefault();

        // Prepare content to send via email
        let sendContent = {
            FirstName: FirstName,
            LastName: LastName,
            Mobile: Mobile,
            Mail: Mail,
            Message: Message
        }

        // Validate form inputs
        if (
            Mail.includes('@' && '.') && // Check for a basic email format
            FirstName !== '' &&
            LastName !== '' &&
            Mobile !== '' &&
            Message !== ''
        ) {
            // Send email using emailjs library
            emailjs
                .send(
                    process.env.REACT_APP_SERVICE_ID,
                    process.env.REACT_APP_CONTACT_TEMPLATE_ID,
                    sendContent,
                    process.env.REACT_APP_PUBLIC_KEY
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );

            // Clear all input values in the form and set a success message
            setFirstName('');
            setLastName('');
            setMobile('');
            setMail('');
            setMessage('');
            setError('Medellande skickad'); // Message indicating successful submission
        } else {
            setError('Uppgifter är ej giltiga'); // Error message for invalid input
        }
    };

    return (
        <form className="w-[90%] mx-auto rounded-lg py-10 " onSubmit={sendTest}>
            <h2 className="text-center text-4xl md:w-1/2 w-3/4 mx-auto p-4 border-b-2 border-[#222020]">Kontakta oss </h2>
            
            <div className=" flex flex-col mt-10">
                <div className="relative mx-auto md:w-1/2 w-full">
                    <label htmlFor="Namn" className="absolute bg-[#F4F3EE] px-2 top-[-13px] left-[9px]" >Namn</label>
                    <input 
                        type="text" 
                        id="Namn" 
                        name="Namn" 
                        className="p-2 rounded-md w-full border-2 bg-[#F4F3EE] border-black" 
                        value={FirstName}
                        onChange={event => setFirstName(event.target.value)}
                    /> 
                </div>
                <div className="relative mx-auto md:w-1/2 w-full mt-4">
                    <label htmlFor="Efternamn" className="absolute bg-[#F4F3EE] px-2 top-[-13px] left-[9px]">Efternamn</label>
                    <input 
                        type="text" 
                        id="Efternamn" 
                        name="Efternamn" 
                        className="p-2 rounded-md w-full border-2 bg-[#F4F3EE] border-black"
                        value={LastName}
                        onChange={event => setLastName(event.target.value)}
                    />    
                </div>
                
            </div>
            <div className=" flex flex-col  mt-4">
                <div className="relative mx-auto md:w-1/2 w-full">
                    <label htmlFor="Telefonnummer" className="absolute bg-[#F4F3EE] px-2 top-[-13px] left-[9px] ">Telefonnummer</label>
                    <input 
                        type="text" 
                        id="Telefonnummer" 
                        name="Telefonnummer" 
                        className="p-2 rounded-md w-full border-2 bg-[#F4F3EE] border-black"
                        value={Mobile}
                        onChange={event => setMobile(event.target.value)}
                    /> 
                </div>
                <div className="relative mx-auto md:w-1/2 w-full mt-4">
                    <label htmlFor="E-postadress" className="absolute bg-[#F4F3EE] px-2 top-[-13px] left-[9px]">E-postadress</label>
                    <input 
                        type="text" 
                        id="E-postadress" 
                        name="E-postadress"
                        className="p-2 rounded-md w-full border-2 bg-[#F4F3EE] border-black"
                        value={Mail}
                        onChange={event => setMail(event.target.value)}
                    />    
                </div>
                
            </div>
            <div className='relative mx-auto md:w-1/2 w-full mt-4'>
                <label htmlFor="message" className="absolute bg-[#F4F3EE] px-2 top-[-13px] left-[12px]">Meddelande</label>

                <textarea 
                    name="message" 
                    id='message' 
                    value={Message}
                    onChange={event => setMessage(event.target.value)}
                    className="w-full md:h-[200px] h-[100px]  border-2 rounded-xl bg-[#F4F3EE] border-black p-4"
                >

                </textarea>                
            </div>
            {errore === 'Uppgifter är ej giltiga' ? (
                <div className='flex justify- mt-8'>
                    <button type="submit" className='mx-auto px-4 py-2 rounded-md border-2 font-bold border-black'> Skicka eddelande <br />
                        <p className='text-red-800 font-bold'>{errore}</p>
                    </button>
                </div>
                ) : (errore === 'Medellande skickad' ? (
                    <div className='flex justify- mt-8'>
                        <button type="submit" className='mx-auto px-4 py-2 rounded-md border-2 font-bold border-black'> Skicka meddelande
                            <p className='text-green-400 font-bold'>{errore}</p>
                        </button>
                    </div> 
                    ) : (
                        <div className='flex justify- mt-8'>
                            <button type="submit" className='mx-auto px-4 py-2 rounded-md border-2 font-bold border-black'> Skicka meddelande</button>
                        </div> 
                    )
                )
            } 
        </form>
    )
}
