// Importing necessary modules from React and other packages
import { useState } from 'react'; // Importing useState hook from React
import emailjs from '@emailjs/browser'; // Importing emailjs for sending emails

import ShoppingCartContext from "../Context/ShoppingCartContext"; // Importing ShoppingCartContext
import { useContext } from "react"; // Importing useContext hook from React

// Defining a functional component ContactForm

export default function OrderForm(){
    // Destructuring necessary functions and values from the ShoppingCartContext
    const { 
        ProductItems, 
        cartItems
    } = useContext(ShoppingCartContext);

    // Initializing states for form fields using the useState hook
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Mail, setMail] = useState('');
    const [Adress, setAdress] = useState('');
    const [PostOrt, setPostort] = useState('');
    const [PostNumber, setPostNumber] = useState('');

    const [errore, setError] = useState(''); // Initializing state for error message

    // Function to handle form submission
    const sendTest = (event) => {
        event.preventDefault(); // Preventing default form submission behavior

        let product = []; // Initializing an empty array to hold product details

        // Looping through cart items and formatting product details
        for (let Item in cartItems) {
            let Cart = {
                Item: ProductItems[cartItems[Item].productID - 1].Name,
                Pris: (ProductItems[cartItems[Item].productID - 1].Price * cartItems[Item].quantity),
                Antal: cartItems[Item].quantity
            };
            product = [...product, Cart]; // Adding formatted product details to the product array
        }

        // Formatting product items for email content
        const formattedItems = product.map((item) => (
            `Item: ${item.Item}, Price: ${item.Pris}, Quantity: ${item.Antal}`
        ));

        const emailContent = `${formattedItems.join('\n')}`; // Creating email content

        // Creating an object with form data to be sent via email
        let sendContent = {
            FirstName: FirstName,
            LastName: LastName,
            Mobile: Mobile,
            Mail: Mail,
            Gatuadress: Adress,
            Postort: PostOrt,
            Postnummer: PostNumber,
            Cart: emailContent,
        };

        // Validating form data before sending email
        if (
            Mail.includes('@' && '.') && 
            FirstName !== '' && 
            LastName !== '' && 
            PostNumber.length === 5 && 
            Mobile !== '' && 
            Adress !== '' && 
            PostOrt !== ''
        ) {
            // Sending email using emailjs
            emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_ORDER_TEMPLATE_ID,
                sendContent,
                process.env.REACT_APP_PUBLIC_KEY
            );

            // Clearing all input values in the form after successful submission
            setFirstName('');
            setLastName('');
            setMobile('');
            setMail('');
            setAdress('');
            setPostort('');
            setPostNumber('');
            setError('Order skickad'); // Setting success message
        } else {
            setError('Uppgifter är ej giltiga'); // Setting error message for invalid form data
        }
    };

    return (
        <form className="w-[90%] mx-auto rounded-lg" onSubmit={sendTest}>
            {/* Form fields for contact details */}
            {/* ... (omitted for brevity) ... */}
            <h2 className="mb-4 md:text-lg text-md font-bold w-full">
                KONTAKT UPPGIFTER  
            </h2>
            
            <div className=" flex md:flex-row flex-col  ">
                <div className="relative md:mx-0 mx-auto md:w-1/2 w-full">
                    <label htmlFor="Namn" className="absolute bg-white px-2 top-[-13px] left-[9px]" >Namn</label>
                    <input 
                        type="text" 
                        id="Namn" 
                        className="p-2 rounded-md w-full border-2 border-black" 
                        value={FirstName}
                        onChange={event => setFirstName(event.target.value)}
                    /> 
                </div>
                <div className="relative md:ml-10 md:mx-0 mx-auto md:w-1/2 w-full md:mt-0 mt-4">
                    <label htmlFor="Efternamn" className="absolute bg-white px-2 top-[-13px] left-[9px]">Efternamn</label>
                    <input 
                        type="text" 
                        id="Efternamn" 
                        className="p-2 rounded-md w-full border-2 border-black"
                        value={LastName}
                        onChange={event => setLastName(event.target.value)}
                    />    
                </div>
                
            </div>
            <div className=" flex md:flex-row flex-col  mt-4">
                <div className="relative md:mx-0 mx-auto md:w-1/2 w-full">
                    <label htmlFor="Telefonnummer" className="absolute bg-white px-2 top-[-13px] left-[9px]">Telefonnummer</label>
                    <input 
                        type="text" 
                        id="Telefonnummer" 
                        className="p-2 rounded-md w-full border-2 border-black" 
                        value={Mobile}
                        onChange={event => setMobile(event.target.value)}
                    /> 
                </div>
                <div className="relative md:ml-10 md:mx-0 mx-auto md:w-1/2 w-full md:mt-0 mt-4">
                    <label htmlFor="E-postadress" className="absolute bg-white px-2 top-[-13px] left-[9px]">E-postadress</label>
                    <input 
                        type="text" 
                        id="E-postadress" 
                        className="p-2 rounded-md w-full border-2 border-black"
                        value={Mail}
                        onChange={event => setMail(event.target.value)}
                    />    
                </div>
                
            </div>

            <h3 className="mt-4 md:text-lg text-md font-bold">
                LEVERANS ADRESS 
            </h3>
            <div className=" flex md:flex-row flex-col  mt-4 ">
                <div className="relative md:mx-0 mx-auto md:w-[40%] w-full">
                    <label htmlFor="Gatuadress" className="absolute bg-white px-2 top-[-13px] left-[9px]">Gatuadress</label>
                    <input 
                        type="text" 
                        id="Gatuadress" 
                        className="p-2 rounded-md w-full border-2 border-black"
                        value={Adress}
                        onChange={event => setAdress(event.target.value)}
                    /> 
                </div>
                
                <div className="relative md:ml-4 md:mx-0 mx-auto md:w-[40%] w-full md:mt-0 mt-4">
                    <label htmlFor="Postort" className="absolute bg-white px-2 top-[-13px] left-[9px]">Postort</label>
                    <input 
                        type="text" 
                        id="Postort" 
                        className="p-2 rounded-md w-full border-2 border-black"
                        value={PostOrt}
                        onChange={event => setPostort(event.target.value)}
                    />    
                </div>
                <div className="relative md:ml-6 md:mx-0 mx-auto md:w-[20%] w-full md:mt-0 mt-4">
                    <label htmlFor="Postnummer" className="absolute bg-white px-2 top-[-13px] left-[9px]">Postnummer</label>
                    <input 
                        type="text" 
                        id="Postnummer" 
                        className="p-2 rounded-md w-full border-2 border-black"
                        value={PostNumber}
                        onChange={event => setPostNumber(event.target.value)}
                    />    
                </div>
            </div>


            {/* Conditional rendering based on error or success message */}
            {errore === 'Uppgifter är ej giltiga' ? (
                // Displaying error message if form data is invalid
                <div className='flex justify- mt-8'>
                    <button type="submit" className='mx-auto px-4 py-2 rounded-md border-2 font-bold'> Skicka order <br />
                        <p className='text-red-800 font-bold'>{errore}</p>
                    </button>
                </div>
            ) : (
                errore === 'Order skickad' ? (
                    // Displaying success message after successful form submission
                    <div className='flex justify- mt-8'>
                        <button type="submit" className='mx-auto px-4 py-2 rounded-md border-2 font-bold'> Skicka order
                            <p className='text-green-400 font-bold'>{errore}</p>
                        </button>
                    </div>
                ) : (
                    // Displaying default button for form submission
                    <div className='flex justify- mt-8'>
                        <button type="submit" className='mx-auto px-4 py-2 rounded-md border-2 font-bold'> Skicka order</button>
                    </div>
                )
            )}
        </form>
    )
}