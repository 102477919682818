// Importing necessary modules from React and other packages
import { Link } from 'react-router-dom'; // Importing Link component from react-router-dom
import ShoppingCartContext from "../Context/ShoppingCartContext"; // Importing ShoppingCartContext
import { useContext, useState } from "react"; // Importing useContext and useState hooks from React
import logo from "../image/logo/Color logo - no background.png"; // Importing the logo image

// Defining the Navbar component
function Navbar() {
    // Destructuring necessary functions and values from the ShoppingCartContext
    const { cartItems } = useContext(ShoppingCartContext);

    // State for controlling the mobile menu
    const [Open, setOpen] = useState(true);

    // Function to count the total number of products in the cart
    let Count = () => {
        let NumProducts = 0;
        for (let i = 0; i < cartItems.length; i++) {
            NumProducts += cartItems[i].quantity;
        }
        return NumProducts;
    };

    // JSX for rendering the Navbar component
    return (
        <header className="sticky top-0 z-40 shadow-xl shadow-[#463F3A]">
            <nav className="bg-[#222020] md:p-8 p-4">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        {/* Button for toggling the mobile menu */}
                        <button className='md:hidden' onClick={() => setOpen(!Open)}>
                            <div className='h-8 w-8'>
                                <div className='border-[1px] mb-2'></div>
                                <div className='border-[1px] my-2'></div>
                                <div className='border-[1px] mt-2'></div>
                            </div>
                        </button>
                        <div className="flex items-center justify-center">
                            {/* Logo */}
                            <div className="flx-shrink-0 ml-8">
                                <Link to="/">
                                    <img className="lg:h-20 md:h-14 sm:h-14 h-14" src={logo} alt="Rals UF loga-" />
                                </Link>
                            </div>
                            {/* Navigation links for larger screens */}
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    <Link to="/shop" className="hover:bg-[#463F3A] border-[#463F3A] border-2 px-3 py-2 rounded-md text-sm font-medium text-white">
                                        <h1>
                                            Shop
                                        </h1>
                                    </Link>
                                    <Link to="/contact" className="hover:bg-[#463F3A] border-[#463F3A] border-2 px-3 py-2 rounded-md text-sm font-medium text-white">
                                        <h1>
                                            Kontakta oss
                                        </h1>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Link to the checkout page with a shopping cart icon */}
                        <Link to="/checkout" className=" ">
                            <div className='md:w-12 md:h-12 h-10 w-10 m-4'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    fill="white"
                                >
                                    <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
                                </svg>
                            </div>
                            {/* Displaying the quantity badge if the cart is not empty */}
                            {cartItems.length > 0 && (
                                <div className='md:top-8 top-4 md:ml-12 ml-10 w-6 h-6 bg-red-600 rounded-full text-center absolute text-white'>
                                    {Count()}
                                </div>
                            )}
                        </Link>
                    </div>
                </div>
            </nav>
            {/* Mobile menu */}
            <div className={`absolute w-full py-3 left-0 bg-[#222020] shadow-xl shadow-[#463F3A] ${Open ? '-translate-y-80' : 'translate-y-0'} ease-in-out duration-500 -z-10`}>
                <div className="ml-10 flex flex-col items-baseline space-x-4">
                    {/* Link to 'Shop' page */}
                    <Link to="/shop" className="hover:bg-[#463F3A] border-[#463F3A] ml-4 border-2 px-3 py-2 rounded-md text-sm font-medium text-white" onClick={() => setOpen(!Open)}>
                        <h1 >
                            Shop
                        </h1>
                    </Link>

                    {/* Link to 'Contact' page */}
                    <Link to="/contact" className="hover:bg-[#463F3A] border-[#463F3A] mt-4 border-2 px-3 py-2 rounded-md text-sm font-medium text-white" onClick={() => setOpen(!Open)}>
                        <h1>
                            Kontakta oss
                        </h1>
                    </Link>
                </div>
            </div>
        </header>
    );
}

export default Navbar; // Exporting the Navbar component