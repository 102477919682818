import { Link } from "react-router-dom";
import Data from "../data/webbPageText.json"

export default function Footer() {
    return (
        <div className="">
            <footer className="w-full bg-[#222020] static bottom-0 flex md:flex-row flex-col-reverse justify-center   px-20 py-8 text-white">
                <h3 className="text-md font-semibold md:mx-0 mx-auto md:my-auto mt-4">&copy; {Data.Footer.CopyRight}</h3>

                <div className="flex flex-col md:ml-10 md:pl-10 md:my-auto md:py-14 py-4 md:border-l-2 md:border-b-0 border-b-2">
                    <h3 className="text-lg mx-2 font-semibold">Kontakta oss</h3>
                    <ul className="felx flex-col mx-2">
                        <li>E-post : {Data.Footer.Email}</li>
                    </ul>
                </div>
                <div className="flex flex-col  md:mx-10 md:px-10 py-2 border-white md:border-x-2 md:border-y-0 border-y-2">
                    <h3 className="mx-2 font-semibold">Följ oss</h3>
                    <ul className="felx flex-col m">
                        <li className="m-2"><a href={Data.Footer.SocialMedia.Facebook}>Facebook</a></li>
                        <li className="m-2"><a href={Data.Footer.SocialMedia.Instagram}>Instagram</a></li>
                        <li className="m-2"><a href={Data.Footer.SocialMedia.TikTok}>TikTok</a></li>
                        <li className="m-2"><a href={Data.Footer.SocialMedia.X}>X</a></li>
                    </ul>
                </div>
                
                
                <div className="flex flex-col py-2">
                    <h3 className="text-lg mx-1 font-semibold">Länkar</h3>
                    <ul className="felx flex-col">
                        <li className="m-1">
                            <Link to="/">Home</Link>                                 
                        </li>
                        <li className="m-1">
                            <Link to="/shop">Shop</Link>  
                        </li>
                        <li className="m-1">
                            <Link to="/contact">Kontakta oss</Link>  
                        </li>
                        <li className="m-1">
                            <Link to="/cookies">Cookies</Link>  
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    );
}