// Importing necessary modules from React
import ShoppingCartContext from "../Context/ShoppingCartContext"; // Importing ShoppingCartContext
import { useContext } from "react"; // Importing useContext hook from React

// Defining the Product component
function Product({ id, productID, quantity }) {
    // Destructuring necessary functions and values from the ShoppingCartContext
    const { 
        increaseCartQuantity, 
        decreaseCartQuantity, 
        ProductItems
    } = useContext(ShoppingCartContext);

    // JSX for rendering the product details
    return (
        <div className="text-black bg-white shadow-md md:w-[90%] w-full mx-auto md:p-4 p-2 my-4">
            <div className="flex flex-row items-center justify-between">
                <div className="">
                    {/* Rendering product image */}
                    <img src={ProductItems[productID - 1].Picture[0].imgURL} alt={ProductItems[productID - 1].id} className="md:h-[100px] sm:h-[100px] h-[50px]" />
                </div>
                <div>
                    {/* Rendering product name */}
                    <h2 className="md:text-2xl text-md text-center">{ProductItems[productID - 1].Name}</h2>
                </div>
                <div className="md:block hidden">
                    {/* Rendering product price */}
                    <h2 className="text-xl">{ProductItems[productID - 1].Price} Kr</h2>
                </div>
                <div className="flex md:flex-row flex-col justify-center">
                    <div className="flex items-center mr-4">
                        {/* Button to increase quantity */}
                        <button onClick={() => increaseCartQuantity(ProductItems[productID - 1].id)} className="md:w-8 md:h-8 w-8 h-8 text-lg border-2 rounded-full">+</button>

                        {/* Rendering product quantity */}
                        <p className="md:mx-4 mx-2">
                            {quantity}
                        </p>

                        {/* Button to decrease quantity */}
                        <button onClick={() => decreaseCartQuantity(ProductItems[productID - 1].id)} className="md:w-8 md:h-8 w-8 h-8 text-lg border-2 rounded-full">-</button>
                    </div>
                    <div className="flex items-center justify-center">
                        {/* Rendering total price for the product */}
                        <h3 className="md:text-xl text-lg font-bold text-center">{ProductItems[productID - 1].Price * quantity} Kr</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product; // Exporting the Product component
